html
,body{
    width: 100%;    
    height: 100%;
}
body{
    background-image: url('./img/back.jpg');
    background-attachment: fixed;
    background-size: cover;
    text-transform: capitalize;   
}
#root{
    min-height: 100%;
}
#main{
    min-height: 570px;
    border-radius: 10px;
    background-color: rgba(250, 255, 250, 0.8);
    
}
select{
    text-transform: capitalize;
}
#bottom-of-form{
    min-height: 115px;
}
#heading{
    padding : 2.1rem 0;
}
#main-spinner{
    top: 50%;
    left : 50%;
    transform: translate(-50%,-50%);

}